/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Thin.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-ThinItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Light.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-LightItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-Italic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Medium.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-MediumItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Bold.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-BoldItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/fonts/Montserrat-Black.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/Montserrat-BlackItalic.woff2") format("woff2"),
  url("./assets/fonts/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Thin.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-ThinItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-ThinItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-ExtraLight.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-ExtraLight.woff") format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Light.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-LightItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-LightItalic.woff") format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Regular.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-Italic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Medium.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-MediumItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-MediumItalic.woff") format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-SemiBold.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-SemiBold.woff") format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Bold.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-BoldItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-BoldItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-ExtraBold.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-ExtraBold.woff") format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/fonts/MontserratAlternates-Black.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/MontserratAlternates-BlackItalic.woff2") format("woff2"),
  url("./assets/fonts/MontserratAlternates-BlackItalic.woff") format("woff");
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe {
  display: none !important;
}

code {
  font-family: Montserrat,
    monospace;
}
